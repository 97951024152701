import styled from 'styled-components'

export const TableContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 16px 16px 24px;
  overflow: scroll;
  
  & > button { 
    align-self: flex-end; 
    margin-right: 8px; 
  }
  
  .table-responsive {
    overflow: visible;
  }
  
  .table-responsive td {
    padding: 0.6rem 0.75rem;
    vertical-align: middle;
  }
  
  &.min .table-responsive td {
    padding: 0.35rem 0.75rem;
  }
  
  td.image, td.image > img {
    width: 32px;
    height: 32px;
  }
  
  td > span {
    font-size: 14px;
  }
`;

export const GraphContainer = styled.div`
  .recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child {
    stroke-opacity: 0;
  }
`;