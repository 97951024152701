import { ListContainer } from 'components/MobileDataItem/MobileDataItem.style'
import React from 'react'

type MobileDataItemListProps = {
  asModule?: boolean;
  className?: string;
  children?: any;
}

const MobileDataItemList: React.FC<MobileDataItemListProps> = ({
  asModule = true,
  className,
  children,
}) => {

  return (
    <ListContainer asModule={asModule} className={className}>
      {children}
    </ListContainer>
  )
}

export default MobileDataItemList
