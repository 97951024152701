import { useEffect, useState } from 'react'
import { addDaysToDate } from 'helper/datetime/dates'

export type FromToDateStringsProps = {
  fromDateStr: string;
  toDateStr: string;
}

export const formatYYYYMMDD = (date: Date): string => {

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const monthStr = month > 9 ? `${month}` : `0${month}`;
  const dayStr = day > 9 ? `${day}` : `0${day}`;

  return `${year}-${monthStr}-${dayStr}`
};

export const useFromToDateStrings = (fromDate: Date, toDate: Date): FromToDateStringsProps => {

  const [fromDateStr, setFromDateStr] = useState<string>(null);

  useEffect(() => {
    let d = fromDate || addDaysToDate(new Date(), -365);
    setFromDateStr(formatYYYYMMDD(d));
  }, [fromDate]);

  const [toDateStr, setToDateStr] = useState<string>(null);

  useEffect(() => {
    let d = toDate || new Date();
    setToDateStr(formatYYYYMMDD(d));
  }, [toDate]);

  return { fromDateStr, toDateStr };
}
