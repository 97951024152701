import React, { useEffect, useState } from 'react'
import RemoteReportDateTimeGraphTooltip from 'components/RemoteReports/DateTimeBreakdown/RemoteReportDateTimeGraphTooltip'
import useRemoteReportsContext from 'contexts/remote-reports'
import { GraphContainer } from 'components/Reports/Report.style'
import usePermissions from 'endpoints/permissions/usePermissions'
import { DateGroupKey, sortDateKeys, dateKeyToLabel } from 'helper/dashboard/remote-reports'
import { ResponsiveContainer, AreaChart, Area, Tooltip, XAxis, YAxis, Legend, CartesianGrid, BarChart, Bar } from 'recharts'

const IN_STORE_COLOR = "var(--devo-green)";
const ONLINE_COLOR = "var(--devo-blue)";

interface RemoteReportDateTimeGraphProps {
  data: any;
  grouping: DateGroupKey;
  style?: "BAR" | "LINE";
  width?: number | string;
  height: number | string;
}

const RemoteReportDateTimeGraph: React.FC<RemoteReportDateTimeGraphProps> = ({
  data,
  grouping,
  style = "BAR",
  width = "100%",
  height,
}) => {

  const { isPosEnabled } = usePermissions();
  const { isMobile, viewMode, timeFrame: timeFrameProps, combinedDataKey, inStoreDataKey, onlineDataKey } = useRemoteReportsContext();
  const { fromDate, toDate } = timeFrameProps;

  const [inputData, setInputData] = useState([]);
  useEffect(() => {
    let set = [];
    let keys = sortDateKeys(Object.keys(data), grouping, fromDate, toDate);
    keys.forEach(key => set.push({
      total: data[key]?.[combinedDataKey] || 0,
      inStore: data[key]?.[inStoreDataKey] || 0,
      online: data[key]?.[onlineDataKey] || 0,
      dateStr: dateKeyToLabel(key, grouping, false),
      shortDateStr: dateKeyToLabel(key, grouping, true)
    }));
    setInputData(set);
  }, [data, grouping, viewMode]);

  const [key, setKey] = useState(1);
  useEffect(() => setKey(key+1), [data]);

  if (inputData.length == 0)
    return <></>;

  if (style == "BAR") {
    return (
      <GraphContainer>
        <ResponsiveContainer width={width} height={height}>
          <BarChart key={`report-${key}`} data={inputData} margin={{ top: 16 }}>
            <CartesianGrid strokeDasharray="4 4" vertical={false} />

            <XAxis dataKey={"shortDateStr"} tickLine={false} />
            <YAxis width={40} axisLine={false} tickLine={false} allowDecimals={false} />

            {isPosEnabled && <Bar name="In Store" dataKey="inStore" barSize={30} fill={IN_STORE_COLOR} radius={[4, 4, 0, 0]} />}
            <Bar name="Online" dataKey="online" barSize={30} fill={ONLINE_COLOR} radius={[4, 4, 0, 0]} />

            {!isMobile && <Tooltip content={<RemoteReportDateTimeGraphTooltip />} />}
            {isPosEnabled && <Legend iconType={'circle'} />}
          </BarChart>
        </ResponsiveContainer>
      </GraphContainer>
    )
  }

  return (
    <GraphContainer>
      <ResponsiveContainer width={width} height={height}>
        <AreaChart key={`report-${key}`} data={inputData}>
          <defs>
            {isPosEnabled &&
              <linearGradient id={"inStore"} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={IN_STORE_COLOR} stopOpacity={0.5} />
                <stop offset="95%" stopColor={IN_STORE_COLOR} stopOpacity={0} />
              </linearGradient>
            }
            <linearGradient id={"online"} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={ONLINE_COLOR} stopOpacity={0.5} />
              <stop offset="95%" stopColor={ONLINE_COLOR} stopOpacity={0} />
            </linearGradient>
          </defs>

          <XAxis dataKey={"shortDateStr"} />

          {isPosEnabled && <Area name="In Store" type="monotone" dataKey={"inStore"} stroke={IN_STORE_COLOR} fillOpacity={1} fill={`url(#inStore)`} />}
          <Area name="Online" type="monotone" dataKey={"online"} stroke={ONLINE_COLOR} fillOpacity={1} fill={`url(#online)`} />

          {!isMobile && <Tooltip content={<RemoteReportDateTimeGraphTooltip />} />}
          {isPosEnabled && <Legend />}
        </AreaChart>
      </ResponsiveContainer>
    </GraphContainer>
  );
}

export default RemoteReportDateTimeGraph;