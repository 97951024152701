import { MobileDataItemBlockProps } from 'components/MobileDataItem/MobileDataItem'
import React from 'react'
import useRemoteReportsContext from 'contexts/remote-reports'
import usePermissions from 'endpoints/permissions/usePermissions'
import { RemoteReportBaseModel } from 'models/RemoteReports/BaseRemoteReports'
import { Caption, Ctd, Cth, Placeholder } from 'styles/Global'

interface RemoteReportStatisticsColumnsProps {
  data?: RemoteReportBaseModel;
}

export const RemoteReportStatisticsColumnsHeaders: React.FC = props => {
  const { isPosEnabled } = usePermissions();
  const { viewModeIncludeExtraRevenue } = useRemoteReportsContext();
  return (<>
    {isPosEnabled && <Cth>IN STORE</Cth>}
    {isPosEnabled && <Cth>ONLINE</Cth>}
    <Cth>TOTAL</Cth>
    {viewModeIncludeExtraRevenue && <Cth>REVENUE</Cth>}
  </>);
}

export const RemoteReportStatisticsColumnsLoading: React.FC = () => {
  const { isPosEnabled } = usePermissions();
  const { viewModeIncludeExtraRevenue } = useRemoteReportsContext();
  return (<>
    {isPosEnabled && <Ctd>
      <Placeholder vMargin={4} width={20} height={16} />
    </Ctd>}
    {isPosEnabled && <Ctd>
      <Placeholder vMargin={4} width={20} height={16} />
    </Ctd>}
    <Ctd>
      <Placeholder vMargin={4} width={20} height={16} />
    </Ctd>
    {viewModeIncludeExtraRevenue && <Ctd>
      <Placeholder vMargin={4} width={20} height={16} />
    </Ctd>}
  </>);
}

export const getRemoteReportStatisticsMobileBlocks = (props: RemoteReportStatisticsColumnsProps): MobileDataItemBlockProps[] => {
  const { data } = props;
  const { isPosEnabled } = usePermissions();
  const { viewModeIncludeExtraRevenue, formatDataValue, combinedDataKey, inStoreDataKey, onlineDataKey } = useRemoteReportsContext();

  let results: MobileDataItemBlockProps[] = []
  results.push({ label: "Total", value: formatDataValue(data, combinedDataKey) });
  if (isPosEnabled) results.push({ label: "In Store", value: formatDataValue(data, inStoreDataKey) });
  if (isPosEnabled) results.push({ label: "Online", value: formatDataValue(data, onlineDataKey) });
  if (viewModeIncludeExtraRevenue) results.push({ label: "Revenue", value: formatDataValue(data, "totalRevenue") });
  return results;
}

const RemoteReportStatisticsColumns: React.FC<RemoteReportStatisticsColumnsProps> = ({
  data,
}) => {
  const { isPosEnabled } = usePermissions();
  const { viewModeIncludeExtraRevenue, formatDataValue, combinedDataKey, inStoreDataKey, onlineDataKey } = useRemoteReportsContext();
  return (<>
    {isPosEnabled && <Ctd>
      <Caption>{formatDataValue(data, inStoreDataKey)}</Caption>
    </Ctd>}
    {isPosEnabled && <Ctd>
      <Caption>{formatDataValue(data, onlineDataKey)}</Caption>
    </Ctd>}
    <Ctd>
      <Caption><b>{formatDataValue(data, combinedDataKey)}</b></Caption>
    </Ctd>
    {viewModeIncludeExtraRevenue && <Ctd>
      <Caption><b>{formatDataValue(data, "totalRevenue")}</b></Caption>
    </Ctd>}
  </>);
}

export default RemoteReportStatisticsColumns;