import React, { useEffect, useState } from 'react'
import RemoteReportDateTimeList from 'components/RemoteReports/DateTimeBreakdown/RemoteReportDateTimeList'
import RemoteReportDateTimeGraph from 'components/RemoteReports/DateTimeBreakdown/RemoteReportDateTimeGraph'
import RemoteReportDateTimeGroupingDropdown from 'components/RemoteReports/DateTimeBreakdown/RemoteReportDateTimeGroupingDropdown'
import { RemoteReportDateBreakdownModel } from 'models/RemoteReports/BaseRemoteReports'
import { Container, GraphContainer, ListContainer } from 'components/RemoteReports/DateTimeBreakdown/RemoteReportDateTimeBreakdown.style'
import useRemoteReportsContext from 'contexts/remote-reports'
import { DateGroupKey, getInitialGroupingForTimeFrame } from 'helper/dashboard/remote-reports'
import { Collapse } from 'react-bootstrap'
import { ModuleHeader, Spacer } from 'styles/Global'

interface RemoteReportDateTimeBreakdownProps {
  title?: string;
  data: RemoteReportDateBreakdownModel;
}

const RemoteReportDateTimeBreakdown: React.FC<RemoteReportDateTimeBreakdownProps> = ({
  title = "Breakdown",
  data
}) => {

  const { isMobile, timeFrame: timeFrameProps } = useRemoteReportsContext();
  const { timeFrame } = timeFrameProps;

  const [grouping, setGrouping] = useState<DateGroupKey>("daily");
  useEffect(() => {
    if (timeFrame) setGrouping(getInitialGroupingForTimeFrame(timeFrame));
  }, [timeFrame]);

  const selectedData = data[grouping];

  if (!selectedData) return <></>;

  const numberOfDates = Object.keys(selectedData).length;
  const showGraph = numberOfDates > 1;
  const currentData = data[grouping];

  return (
    <Container id={"remote-report-date-time-breakdown"} className={"module"}>
      <ModuleHeader>
        <h3>{title}</h3>
        <Spacer />
        <RemoteReportDateTimeGroupingDropdown
          grouping={grouping}
          setGrouping={setGrouping} />
      </ModuleHeader>

      <Collapse in={showGraph}>
        <GraphContainer>
          <RemoteReportDateTimeGraph
            style={"LINE"}
            data={currentData}
            grouping={grouping}
            height={isMobile ? 200 : 300} />
        </GraphContainer>
      </Collapse>

      <ListContainer>
        <RemoteReportDateTimeList
          data={currentData}
          grouping={grouping} />
      </ListContainer>
    </Container>
  )
}

export default RemoteReportDateTimeBreakdown;