import styled from 'styled-components'
import { Module, StyleBreakpoints } from 'styles/Global'

export const Container = styled(Module)``;

export const GraphContainer = styled.div`
  padding: 24px;
  
  @media (max-width: ${StyleBreakpoints.MEDIUM}px) {
    padding: 16px 16px 0 16px;
  }
`;

export const DropdownRow = styled.div`
  display: flex;
  align-items: center;
  text-transform: none;
`;

export const ListContainer = styled.div``;