import React, { forwardRef } from 'react'
import { DropdownRow } from 'components/RemoteReports/DateTimeBreakdown/RemoteReportDateTimeBreakdown.style'
import { Dropdown } from 'react-bootstrap'
import { IconButton, Spacer } from 'styles/Global'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { DATE_GROUP_KEY_LABELS, DateGroupKey } from 'helper/dashboard/remote-reports'

interface RemoteReportDateTimeGroupingDropdownProps {
  grouping: DateGroupKey;
  setGrouping: (grouping: DateGroupKey) => void;
}

interface RemoteReportDateTimeGroupingDropdownItemProps {
  active: DateGroupKey;
  value: DateGroupKey;
  setValue: (value: DateGroupKey) => void;
}

const Item = ({ value, setValue, active }: RemoteReportDateTimeGroupingDropdownItemProps) => (
  <Dropdown.Item onClick={() => setValue(value)}>
    <DropdownRow>
      <span>By {DATE_GROUP_KEY_LABELS[value] || "Invalid"}</span>
      <Spacer style={{ minWidth: 50 }} />
      {value === active && <FontAwesomeIcon icon={faCheck} color="var(--secondary-label-color)" />}
    </DropdownRow>
  </Dropdown.Item>
)

const RemoteReportDateTimeGroupingDropdown = ({ grouping, setGrouping } : RemoteReportDateTimeGroupingDropdownProps) => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} label={`By ${DATE_GROUP_KEY_LABELS[grouping]}`} />

      <Dropdown.Menu>
        <Item active={grouping} value={"daily"} setValue={setGrouping} />
        <Item active={grouping} value={"weekly"} setValue={setGrouping} />
        <Item active={grouping} value={"monthly"} setValue={setGrouping} />
        <Item active={grouping} value={"yearly"} setValue={setGrouping} />
        <Item active={grouping} value={"hourly"} setValue={setGrouping} />
        <Item active={grouping} value={"dayOfWeek"} setValue={setGrouping} />
      </Dropdown.Menu>
    </Dropdown>
  )
}

interface CustomToggleProps {
  variant: string;
  label: string;
  onClick: any;
}

const CustomToggle = forwardRef<any, CustomToggleProps>(({ onClick, label }, ref) => (
  <div ref={ref}>
    <IconButton ref={ref} onClick={onClick} icon={faCalendarAlt}>
      {label}
    </IconButton>
  </div>
));

export default RemoteReportDateTimeGroupingDropdown