import React, { useEffect, useState } from 'react'
import RemoteReportStatisticsColumns, {
  getRemoteReportStatisticsMobileBlocks,
  RemoteReportStatisticsColumnsHeaders
} from 'components/RemoteReports/RemoteReportStatisticsColumns'
import MobileDataItem from 'components/MobileDataItem/MobileDataItem'
import MobileDataItemList from 'components/MobileDataItem/MobileDataItemList'
import useRemoteReportsContext from 'contexts/remote-reports'
import { TableContainer } from 'components/Reports/Report.style'
import { DateGroupKey, dateKeyToLabel, sortDateKeys, DATE_GROUP_KEY_LABELS, BASE_DATA_KEY_FORMAT_AS_PRICE } from 'helper/dashboard/remote-reports'
import { RemoteReportBaseModel, RemoteReportDateBreakdownItemModel } from 'models/RemoteReports/BaseRemoteReports'
import Table from 'react-bootstrap/Table'
import { Label, TableHeader } from 'styles/Global'

interface RemoteReportDateTimeListProps {
  data: RemoteReportDateBreakdownItemModel;
  grouping: DateGroupKey;
}

interface ListItemModel {
  label: string;
  data: RemoteReportBaseModel;
}

const RemoteReportDateTimeList: React.FC<RemoteReportDateTimeListProps> = ({
  data,
  grouping,
}) => {

  const { isMobile } = useRemoteReportsContext();

  const [models, setModels] = useState<ListItemModel[]>([]);

  useEffect(() => {
    let dateKeys = sortDateKeys(Object.keys(data), grouping);
    if (grouping != "hourly" && grouping != "dayOfWeek") dateKeys = dateKeys.reverse();

    let models: ListItemModel[] = dateKeys.map(dateKey => {
      return { label: dateKeyToLabel(dateKey, grouping), data: data[dateKey] };
    });
    setModels(models.filter(model => !!model.data));
  }, [data, grouping]);

  if (isMobile) return (
    <MobileDataItemList>
      {models.map(({ label, data }) => (
        <MobileDataItem
          title={label}
          blocks={getRemoteReportStatisticsMobileBlocks({ data })}
          scrollBlocks
        />
      ))}
    </MobileDataItemList>
  )

  return (
    <TableContainer>
      <Table responsive>
        <TableHeader>
          <tr>
            <th>{DATE_GROUP_KEY_LABELS[grouping]?.toUpperCase() || ""}</th>
            <RemoteReportStatisticsColumnsHeaders />
          </tr>
        </TableHeader>

        <tbody>
          {models.map(({ label, data }) => (
            <tr key={label}>
              <td>
                <Label>{label}</Label>
              </td>
              <RemoteReportStatisticsColumns data={data} />
            </tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
}

export default RemoteReportDateTimeList;