import React from 'react'
import {
  Tooltip,
  TooltipValueContainer,
  KeyLabel,
  ValueLabel,
  TooltipContent
} from 'components/RemoteReports/DateTimeBreakdown/RemoteReportDateTimeGraphTooltip.style'
import useRemoteReportsContext from 'contexts/remote-reports'
import { formatPriceSeparateFractional, formatPriceSeparateWhole } from 'helper/product'
import { ModuleHeader } from 'styles/Global'

const RemoteReportDateTimeGraphTooltip: React.FC<any> = (props) => {

  const { viewModeFormatAsPrice } = useRemoteReportsContext();

  let title = props.payload[0]?.payload.dateStr;
  if (!title) return <></>;

  let total: number = props.payload.map(x => x.value as number).reduce((prev, curr) => prev+curr, 0);

  return (
    <Tooltip>
      <ModuleHeader className={"header"}>
        <h3>{title}</h3>
      </ModuleHeader>

      <TooltipContent>
        <TooltipValueContainer>
          <KeyLabel color={"black"}>Total</KeyLabel>
          <ValueLabel>
            {viewModeFormatAsPrice && <span>£</span>}
            {formatPriceSeparateWhole(total)}
            {viewModeFormatAsPrice && <span>.{formatPriceSeparateFractional(total)}</span>}
          </ValueLabel>
        </TooltipValueContainer>

        {props.payload.map(item => (
          <TooltipValueContainer key={item.name}>
            <KeyLabel color={item.color}>{item.name}</KeyLabel>
            <ValueLabel>
              {viewModeFormatAsPrice && <span>£</span>}
              {formatPriceSeparateWhole(item.value)}
              {viewModeFormatAsPrice && <span>.{formatPriceSeparateFractional(item.value)}</span>}
            </ValueLabel>
          </TooltipValueContainer>
        ))}
      </TooltipContent>
    </Tooltip>
  )
}

export default RemoteReportDateTimeGraphTooltip;