import styled from 'styled-components'
import { Module } from 'styles/Global'

export const Tooltip = styled(Module)`
  box-shadow: var(--devo-heavy-shadow);
  
  .header {
    padding: 20px 16px;
  }
  
  h3 {
    font-size: 16px;
  }
`;

export const TooltipContent = styled.div`
  padding: 20px 16px;
  display: flex;
  flex-flow: row nowrap;
  gap: 32px;
`;

export const TooltipValueContainer = styled.div``;

interface TooltipLegendProps {
  color: string;
}
export const KeyLabel = styled.p<TooltipLegendProps>`
  color: ${props => props.color || "black"};
  margin: 0;
`;

export const ValueLabel = styled.p`
  margin: 0;
  font-size: 32px;
  font-weight: 600;
  & > span {
    font-weight: 600;
    font-size: 20px;
  }
`;
